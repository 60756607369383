@import '../../variables.scss';

.container {
    background-color: #fff;
    padding: 10px 20px;
    border-bottom: 1px solid #eeeeee90;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);

    img.logo {
        width: 56px;
        height: 56px;
        vertical-align: middle;
    }

    p.logoText {
        font-size: 14px;
        color: $mainDark;
        font-weight: 500;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        top: 6px;

        .name {
            display: block;
            font-style: italic;
            font-size: 1.2rem;
            font-weight: bold;
            background-image: linear-gradient(45deg, #5e72eb, #ff5590);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}

.linkContainer {
    display: inline-block;
}