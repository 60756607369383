.widthContainer {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 10px 20px;
}

.primaryBtn {
    position: relative;
    background-image: linear-gradient(45deg, #5e72eb, #ff5590);
    background-position: 0% 50%;
    background-size: 200% 200%;

    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    padding: 10px 20px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    animation: changeColor 4s infinite alternate;
}

@keyframes changeColor {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}