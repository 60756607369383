.container {
  position: absolute;
  z-index: 10;
  top: 30px;
  background-color: #fff;
  right: 10%;
  max-width: 300px;
  max-height: 600px;
  width: 100%;
  padding: 0 20px;
  padding-top: 40px;
  margin: 0 auto;
  border-radius: 4px;
  box-shadow: 0 0 30px rgba(0, 0, 0, .1), inset 0 0 30px rgba(255, 255, 255, 0.1);
}

.positionedHeader {
  overflow: hidden;
  border-radius: 10px 10px 0 0;
  width: 300px;
  position: absolute;
  z-index: 20;
  top: 30px;
  background-color: #fff;
  right: 10%;
}

.positionedHeaderFixed {
  overflow: hidden;
  border-radius: 0 0 0 0;
  width: 300px;
  position: fixed;
  z-index: 20;
  top: 0;
  background-color: #fff;
  right: 10%;

  @media screen and (max-width: 960px) {
    width: 100%;
    right: 0;
  }
}

.starsBackgorund {

  @function multiple-box-shadow ($n) {
    $value: '#{random(3000)}px #{random(3000)}px #fff';

    @for $i from 1 through $n {
      @if $i % 2==0 {
        $value: '#{$value} , #{random(3000)}px #{random(3000)}px #ffffff50';
      }

      @else {
        $value: '#{$value} , #{random(3000)}px #{random(3000)}px #ffffff';
      }
    }

    @return unquote($value);
  }

  .galaxy {
    z-index: -1;
    overflow: hidden;
    // background: transparent;
    background-image: linear-gradient(45deg, #0d0745, #101023);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .starsSmall,
  .starsSmall::after {
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background: transparent;
    box-shadow: multiple-box-shadow(2000);
    animation: animStar 100s linear infinite;
    animation-delay: -10s;
  }

  .starsMedium,
  .starsMedium::after {

    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: transparent;
    box-shadow: multiple-box-shadow(1200);
    animation: animStar 150s linear infinite;
    animation-delay: -8s;
  }

  .starsLarge,
  .starsLarge::after {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: transparent;
    box-shadow: multiple-box-shadow(800);
    animation: animStar 200s linear infinite;
    animation-delay: -5s;
  }

  @keyframes animStar {
    from {
      transform: translateY(-1500);
    }

    to {
      transform: translateY(-3000px);
    }
  }
}