.mainTitle {
    font-size: 2.4rem;
    margin-top: 50px;
}


.info {
    font-size: 1.2rem;
    color: #5e72eb;
    padding: 10px 0;
    font-weight: bold;
    border-radius: 50px;
}

.heroImg {
    margin-right: 20px;
    width: 60px;
    height: auto;
    vertical-align: middle;
}