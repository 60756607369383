.projectsContainer {
    position: relative;
    display: flex;

    @media screen and (max-width: 960px) {
        position: static;
        display: block;
    }
}

.project {
    flex: 1;
    min-width: 100vw;
    height: 100vh;
    background-size: cover;
    position: relative;
    box-shadow: inset 0 0 100px 300px rgba(0, 0, 0, .3);

    @media screen and (max-width: 960px) {
        background-position: center center;
    }
}

.projectContent {
    color: #fff;
    position: absolute;
    padding: 4rem;
    bottom: 20px;
    left: 120px;
    width: 100%;
    max-width: 640px;

    @media screen and (max-width: 960px) {
        position: static;
        padding: 2rem;
        text-align: center;
    }

    h2 {
        font-size: 3rem;

        @media screen and (max-width: 960px) {
            font-size: 1.6rem;
        }
    }

    p {
        font-size: 1.2rem;
        line-height: 2rem;

        @media screen and (max-width: 960px) {
            font-size: 1rem;
            text-overflow: ellipsis;
            white-space: wrap;
            height: 120px;
            overflow: hidden;
        }
    }
}

.svgBtnArrow {
    position: absolute;
    top: 7px;
    bottom: 20px;
    right: 10px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    opacity: 0;
    transform-origin: center center;
    transition: all .3s ease-in-out;
}


.primaryBtnProject {
    transition: all 1s;
    padding-right: 20px;

    &:hover {
        padding-right: 50px;

        .svgBtnArrow {
            animation: rotationButtonAndMoveUp .5s forwards;
            opacity: 1;
        }
    }
}

@keyframes rotationButtonAndMoveUp {
    0% {
        opacity: 0;
        transform: rotate(0deg) translateY(100px);
    }

    100% {
        opacity: 1;
        transform: rotate(360deg) translateY(0);
    }

}