.sectionContainer {
    position: relative;
    display: flex;
    height: 600px;
    padding: 30px 20px;
    background-image: linear-gradient(45deg, #0d0745, #101023);
    overflow: hidden;
}

// .logosContainer {
//     transform: rotate(45deg);
// }

.logoContainer {
    position: absolute;
    z-index: 10;
    margin: auto;
    padding: 6px;
    margin: auto;
    background-color: transparent;
    backdrop-filter: blur(10px);
    border: 2px solid #5e72eb80;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    cursor: pointer;
    transform-origin: center center;
    transition: all .2s;

    &:hover {
        transform: scale(1.1);
    }

    img {
        width: 60px;
        height: auto;
        display: block;
        margin: 0 auto;
        border-radius: 50px;
    }

    >span {
        left: 50%;
        position: absolute;
        bottom: -30px;
        display: block;
        padding-top: 6px;
        transform: translateX(-50%);
    }
}

.tooltipTechLogo {
    position: absolute;
    z-index: 10;
    margin: auto;
    padding: 6px;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    padding: 30px;
    background-color: #fff;
    border-radius: 4px;
    max-width: 300px;
    text-align: center;
    font-size: .9rem;
    line-height: 1.4rem;
    top: 50px;

    ::after {
        content: '';
        position: absolute;
        bottom: -5px;
        width: 10px;
        height: 10px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        background-color: #fff;
    }

    @media screen and (max-width: 960px) {
        width: 300px;
    }
}

.logoContainerActive {
    @extend .logoContainer;
    border: 2px solid #5e72eb;
    box-shadow: 0 0 30px #5e72eb;
    transform: scale(1.1);
}

.strokeContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 0;
    transform: translateX(-50%);
    z-index: 0;
    border-top: 2px solid #5e72eb80;
    border-radius: 50%;
    width: 72%;
    height: 1000px;
}

@function multiple-box-shadow ($n) {
    $value: '#{random(3000)}px #{random(3000)}px #fff';

    @for $i from 1 through $n {
        @if $i % 2==0 {
            $value: '#{$value} , #{random(3000)}px #{random(3000)}px #ffffff50';
        }

        @else {
            $value: '#{$value} , #{random(3000)}px #{random(3000)}px #ffffff';
        }
    }

    @return unquote($value);
}

.galaxyShadow {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 300px;
    background-image: linear-gradient(180deg, #101023, transparent);
}

.galaxy {
    overflow: hidden;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.starsSmall,
.starsSmall::after {
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background: transparent;
    box-shadow: multiple-box-shadow(2000);
    animation: animStar 100s linear infinite;
    animation-delay: -10s;
}

.starsMedium,
.starsMedium::after {

    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: transparent;
    box-shadow: multiple-box-shadow(1200);
    animation: animStar 150s linear infinite;
    animation-delay: -8s;
}

.starsLarge,
.starsLarge::after {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: transparent;
    box-shadow: multiple-box-shadow(800);
    animation: animStar 200s linear infinite;
    animation-delay: -5s;
}

@keyframes animStar {
    from {
        transform: translateY(-1500);
    }

    to {
        transform: translateY(-3000px);
    }
}