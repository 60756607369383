.resumeContainer {

    position: relative;
    padding: 100px;
    min-height: 100vh;
    background-image: linear-gradient(45deg, #0d0745, #101023);
    padding-bottom: 500px;

    @media screen and (max-width: 960px) {
        text-align: center;
        padding: 50px 20px;
        padding-bottom: 500px;
    }

}

.title {
    position: relative;
    z-index: 10;
    color: #fff;
    font-size: 4rem;
    text-align: center;
    margin-bottom: 25px;

    @media screen and (max-width: 960px) {
        font-size: 2rem;
    }
}

.timelineContainer {
    position: relative;
    z-index: 10;
    height: 700px;

    &:focus {
        outline: none;
    }
}

.yearContainer {
    height: 50px;
    border-top: 2px solid #fff;
    margin: 10px;
    flex: 1;
    color: #fff;

    span {
        display: block;
        font-size: 1.5rem;
        font-weight: 600;
        padding: 10px;
        text-align: center;
    }
}

.canvas {
    outline: none;
    box-shadow: none;
    transition: all .5s;
    // border-radius: 10px;
    transform: scale(.8);
    opacity: .5;
    outline: 2px solid #5e72eb;
    border-radius: 4px;

    &.focused {
        opacity: 1;
        transform: scale(.9);
        outline: 2px solid #5e72eb;
        box-shadow: 0 0 30px 500px #5e72eb;
        border-radius: 4px;
    }

    @media screen and (max-width: 960px) {
        transform: scale(1);

        &.focused {
            transform: scale(1);
        }
    }
}

.card {
    position: absolute;
    z-index: 10;
    margin: auto;
    padding: 6px;
    display: block;
    padding: 30px;
    background-color: #fff;
    border-radius: 4px;
    font-size: .9rem;
    line-height: 1.4rem;
    width: 100%;
    max-width: 400px;
    font-size: .8rem;
    left: 50%;
    visibility: hidden;

    @media screen and (max-width: 960px) {
        visibility: visible;
    }

    img {
        width: 100%;
        max-width: 100px;
        border-radius: 50%;
    }

    @media screen and (max-width: 960px) {
        max-width: 300px;
    }
}

.innerTitle {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 10px;
}



.instructions {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;

    img {
        display: block;
        width: 100px;
        height: auto;
        margin: 0 auto;
        margin-top: 20px;
    }

    p {
        font-style: italic;
    }
}

.clickPlay {
    display: block;
    cursor: pointer;
    padding: 10px 20px;
    background-color: #5e72eb;
    color: #fff;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 700;
    border: none;
    margin: 0 auto;
}

.closeGame {
    position: absolute;
    z-index: 10;
    top: 20px;
    right: -20px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    color: #fff;
    padding: 10px;
    background-color: #00000050;
    border: none;
    border-radius: 50%;
    background-image: url('../../assets/img/closeIcon.png');
    background-size: cover;
}

.mobilePlayBtn {
    background-color: #5e72eb;
    border: 1px solid #5e72eb;
    padding: 10px 30px;
    margin: 1px;
    border-radius: 4px;
    color: #fff;
    flex: 1;
    font-weight: bold;
}

.mobilePlayBtnFull {
    @extend .mobilePlayBtn;
    width: 100%;
}

.mobilePlayBtnFocused {
    background-color: #101023;
    box-shadow: 0 0 14px 4px #5e72eb;
}