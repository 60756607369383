@import url('https://fonts.googleapis.com/css2?family=Kantumruy+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Lobster&display=swap');

/* font-family: 'Kantumruy Pro', sans-serif;
font-family: 'Lobster', cursive; */

::-webkit-scrollbar {
  width: 0px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  font-family: 'Kantumruy Pro', sans-serif;
}

Link,
a {
  text-decoration: none;
}







.horizontal-scroller {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  gap: 5px;
  width: 100%;
  height: 100vh;
}

.horizontal-wrapper {
  width: 100%;
  height: calc(100vh + 210vw);
}

.horizontal-container {
  width: 100%;
  height: calc(100vh + 210vw);
  margin-top: 2em;
}

.row {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
}

.item {
  padding: 5vw;
  border: 5px solid white;
  border-radius: 5vw;
  box-sizing: border-box;
  font-size: 2vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 50vh;
  color: white;
  font-weight: normal;
  width: 50vw;
}

.background-demo {
  background-image: url('./assets/background-recipe.png');
  background-size: cover;
  height: 100%;
}

figure img {
  width: 100%;
  height: auto;
  border-radius: 4px;
}