.storyContainer {
    background-color: #fff;
    padding: 50px 20px;
    display: flex;

    @media screen and (max-width: 960px) {
        flex-direction: column;
        padding: 10px;
        text-align: center;
    }
}

.mainTitle {
    font-size: 4rem;
}

.storyText {

    font-size: 1.2rem;
    line-height: 1.5;
    height: 500px;
    overflow: auto;
    margin-right: 10px;
    padding: 10px 20px;
    max-width: 60vw;

    @media screen and (max-width: 960px) {
        max-width: 100vw;
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: #e3e3e3;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #333;
    }
}


.meImg {
    width: 500px;
    height: auto;
}