.footer {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 300px;
    width: 100%;
    padding: 0 20px;
    padding-top: 40px;
    background-color: #101023;
    color: #fff;
    margin: 0 auto;
    border-radius: 4px;
    box-shadow: 0 0 30px rgba(0, 0, 0, .1), inset 0 0 30px rgba(255, 255, 255, 0.1);
    border: 1px solid #232323;
    text-align: center;
}

.title {
    text-align: center;
}

.contactLinks {

    margin: 0 auto;

    ul {
        list-style: none;
        padding: 0;

        li {
            margin: 10px;

            a {
                color: #fff;
                text-decoration: none;
                display: block;
                padding: 10px;
                border-radius: 4px;
                transition: all .2s;
                border: 2px solid #333;

                &:hover {
                    border: 2px solid #5e72eb;
                    background-color: #5e72eb;
                }
            }
        }
    }
}

.reserved {
    display: block;
    margin-bottom: 20px;
    margin-top: 40px;
    font-size: .8rem;
    color: #fec195;
}